.c-radio-container {
  display: flex;
  justify-self: flex-start;
  align-items: center;
}

.c-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-radio:nth-child(2) {
  margin-inline: 1rem;
}

.c-radio__item {
  appearance: none;
}

.c-radio__item + .c-radio__label {
  background-color: transparent;
  color: #f1eff9;
  border: 2px solid #f1eff9;
  border-radius: 1rem;
}

.c-radio__label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.5625rem;
  text-transform: uppercase;
  padding: 1.5rem 2em;
  transition-duration: 0.5s;
  transition-property: color;
  transform: none;
  cursor: pointer;
}

.c-radio__item:checked + .c-radio__label {
  background-color: #f1eff9;
  color: #161623;
  border: 2px solid #f1eff9;
}

@media (max-width: 768px) {
  .c-radio-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .c-radio:nth-child(2) {
    margin-inline: 0;
  }

  .c-radio__label {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .c-radio-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .c-radio:nth-child(2) {
    margin-inline: 0;
  }
}
