.c-progress-bg {
  width: 100%;
  height: 7px;
  border-radius: 2rem;
  background-color: #232336;
  overflow: hidden;
}

.c-progress {
  height: 7px;
}
