.c-toast {
  position: relative;
  background-color: #2d4bf8;
  border-radius: 1.25rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block: 2rem;
  padding: 3.75rem;
}

.c-toast--triangle {
  position: absolute;
  bottom: -49px;
  left: 0;
  right: 0;
  margin-inline: auto;
  clip-path: polygon(0 0, 100% 0%, 50% 100%);
  background-color: #2d4bf8;
  width: 100px;
  height: 50px;
}

.c-toast.hide {
  display: none;
}

.c-toast__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-toast__header p {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2.2rem;
}

.c-toast__header p img {
  height: 2.2rem;
}

.c-toast__header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.c-toast__content p {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.5625rem;
}

.c-toast__content span {
  width: fit-content;
  background-color: transparent;
  font-size: 1.25rem;
  font-family: "AtkinsonHyperlegible-Bold";
  color: #ffffff;
  text-transform: uppercase;
  border: 2px solid #ffffff;
  border-radius: 1rem;
  display: inline-flex;
  align-items: baseline;
  gap: 1rem;
  margin: 0 0.5rem;
  padding: 1.5rem 2.5rem;
}

.c-toast__content img {
  align-self: center;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-toast {
    padding: 1.75rem;
  }

  .c-toast__header p {
    font-size: 1.5rem;
  }

  .c-toast__content p {
    font-size: 1.25rem;
  }

  .c-toast__content span {
    display: flex;
    margin: 0.5rem 0;
    padding: 1rem 2rem;
  }
}
