.c-career-accordion {
  width: 100%;
  margin-block: 1rem;
}

.c-career-accordion__btn {
  width: 70vw;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  cursor: pointer;
  border-radius: 1rem;
  background-color: transparent;
  border: 1px solid #ffffff1a;
  background-color: #1e1e30;
  gap: 0.5rem;
  padding: 2rem 4rem;
}

.c-career-accordion__btn--public {
  grid-template-columns: 0fr 4fr 1fr;
}

.c-career__checkbox {
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"][class="c-career__checkbox"]:not(:checked)::before {
  content: url("../assets/images/heart.svg");
}

input[type="checkbox"][class="c-career__checkbox"]:checked::before {
  content: url("../assets/images/plain_blue_heart.svg");
}

.c-career-accordion__btn label {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  justify-self: start;
  align-self: center;
}

.c-career-accordion__btn h3 {
  grid-column: 2 / 5;
  grid-row: 1 / -1;
  justify-self: start;
  align-self: center;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 3.125rem;
  color: #ffffff;
  text-align: left;
}

.c-career-accordion__btn img {
  grid-column: 5 / 6;
  grid-row: 1 / -1;
  justify-self: end;
  align-self: center;
}

.c-career-accordion__btn.active {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

.c-career-accordion__content {
  display: none;
}

.c-career-accordion__content.open {
  display: block;
  border: 1px solid #ffffff1a;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: #1e1e30;
  padding: 4rem;
}

.c-career-accordion-content__title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2.5rem;
  color: #ffffff;
}

.c-career-accordion-content__title img {
  height: 2.5rem;
}

/*****Description*****/

.c-career-description-container p {
  margin-block: 2rem;
}

/*****Characteristics*****/

.c-career-characteristics-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-block: 4rem;
}

.c-career-characteristic-box {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
  background-color: #232336;
  border: 1px solid #ffffff1a;
}

.c-career-characteristic-box span {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 5rem;
  color: #ffffff;
  text-align: center;
}

.c-career-characteristic-box p {
  font-family: "AtkinsonHyperlegible-Regular";
  color: #ffffff;
  text-align: center;
}

/*****Tasks*****/

.c-career-tasks-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-block: 4rem;
}

.c-career-task-box {
  position: relative;
  padding: 2rem;
  border-radius: 1rem;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.875rem;
  color: #ffffff;
  overflow: hidden;
}

.c-career-task-box--blue {
  background-color: #2d4bf8;
  border: 1px solid #2d4bf8;
}

.c-career-task-box--pink {
  background-color: #dc1e6e;
  border: 1px solid #dc1e6e;
}

.c-career-task-box--orange {
  background-color: #cc3300;
  border: 1px solid #cc3300;
}

.c-career-task-box--green {
  background-color: #1d8640;
  border: 1px solid #1d8640;
}

.c-career-task-box--purple {
  background-color: #8c6ffe;
  border: 1px solid #8c6ffe;
}

/*****Workplaces*****/

.c-career-workplaces-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-block: 4rem;
}

.c-career-workplace-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #232336;
  border: 1px solid #ffffff1a;
}

.c-career-workplace-box__img {
  flex-basis: 30%;
}

.c-career-workplace-box__img img {
  max-width: 100%;
  height: auto;
}

.c-career-workplace-box__content {
  flex-basis: 70%;
  font-family: "AtkinsonHyperlegible-Regular";
  color: #ffffff;
}

/*****Skills*****/

.c-career-skills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.c-career-skills p a {
  color: #6C81FA;
  text-decoration: underline;
}

.c-career-skills-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.c-career-skills-container ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-career-accordion__btn {
    width: 90vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 1rem 2rem;
  }

  .c-career-accordion__btn label {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: start;
    align-self: center;
  }

  .c-career-accordion__btn h3 {
    font-size: 2.2rem;
    grid-column: 1 / -1;
    grid-row: 2 / -1;
    justify-self: start;
    align-self: center;
  }

  .c-career-accordion__btn img {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
    justify-self: end;
    align-self: center;
  }

  .c-career-accordion__content.open {
    padding: 2rem;
  }

  .c-career-accordion-content__title {
    font-size: 1.5rem;
  }

  /*****Characteristics*****/

  .c-career-characteristics-container {
    grid-template-columns: 1fr;
    margin-block: 2rem;
  }

  /*****Tasks*****/

  .c-career-task-box {
    font-size: 1.5625rem;
  }

  .c-career-tasks-container {
    grid-template-columns: 1fr;
    margin-block: 2rem;
  }

  /*****Workplaces*****/

  .c-career-workplaces-container {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    margin-block: 2rem;
  }

  .c-career-workplace-box {
    flex-direction: column;
  }

  .c-career-workplace-box__img {
    flex-basis: 100%;
  }

  .c-career-workplace-box__content {
    flex-basis: 100%;
  }

  /*****Skills*****/
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-career-accordion__btn {
    width: 80vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 2rem;
  }

  .c-career-accordion__btn--public {
    grid-template-rows: none;
  }

  .c-career-accordion__btn label {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: start;
    align-self: center;
  }

  .c-career-accordion__btn h3 {
    grid-column: 1 / -1;
    grid-row: 2 / -1;
    justify-self: start;
    align-self: center;
    font-size: 2.5rem;
  }

  .c-career-accordion__btn img {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
    justify-self: end;
    align-self: center;
  }

  .c-career-accordion__content.open {
    padding: 2rem;
  }

  /*****Characteristics*****/

  .c-career-characteristics-container {
    grid-template-columns: 1fr 1fr;
  }

  /*****Tasks*****/

  .c-career-tasks-container {
    grid-template-columns: 1fr;
  }

  /*****Workplaces*****/

  .c-career-workplaces-container {
    grid-template-columns: 1fr;
  }

  /*****Skills*****/
}

@media only screen and (min-width: 1401px) and (max-width: 1720px) {
  .c-career-accordion__btn {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 2rem;
  }

  .c-career-accordion__btn--public {
    grid-template-rows: none;
  }

  .c-career-accordion__btn label {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: start;
    align-self: center;
  }

  .c-career-accordion__btn h3 {
    font-size: 2.5rem;
    grid-column: 1 / -1;
    grid-row: 2 / -1;
    justify-self: start;
    align-self: center;
  }

  .c-career-accordion__btn img {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
    justify-self: end;
    align-self: center;
  }

  .c-career-accordion__content.open {
    padding: 2rem;
  }

  /*****Characteristics*****/

  .c-career-characteristics-container {
    grid-template-columns: 1fr 1fr;
  }

  /*****Tasks*****/

  .c-career-tasks-container {
    grid-template-columns: 1fr 1fr;
  }

  /*****Workplaces*****/

  /*****Skills*****/
}