.c-accordion__title--career-skills-courses {
    font-family: "AtkinsonHyperlegible-Bold";
    font-size: 1.25rem;
    color: #ffffff;
    text-align: left ;
}

.c-accordion__title--career-skills-courses span {
    font-family: "AtkinsonHyperlegible-Regular";
}

.c-accordion__content--career-skills-courses.open {
    padding: 2rem;
}

.c-courses {
    margin-top: 2rem;
    padding-top: 2rem;
}

.c-courses__title {
    font-family: "AtkinsonHyperlegible-Bold";
    font-size: 2.5rem;
    color: #ffffff;
}

.c-courses__title img {
    height: 2.5rem;
}

.c-courses__list  {
    margin-top: 2rem;
}

.c-courses__list ul {
    list-style: none;
}

