.c-payment {
  width: 70vw;
  background-color: #f1eff9;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin: 2rem;
  padding: 3.75rem;
}

.c-payment--info {
  background-color: #f7af2f;
}

.c-payment h3 {
  font-size: 1.8625rem;
  color: #10101b;
}

.c-payment p {
  font-size: 1.5625rem;
  color: #10101b;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-payment {
    width: 90vw;
    gap: 0.5rem;
    margin: 0;
    margin-block: 2rem;
    padding: 1.75rem;
  }

  .c-payment h3 {
    font-size: 1.5rem;
  }

  .c-payment p {
    font-size: 1.25rem;
  }
}
