.c-offline-alert {
  position: fixed;
  margin-top: 6.9rem;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding-block: 0.5rem 1rem;
  background-color: #edb201;
  z-index: 2;
}

.c-offline-alert p {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #161623;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-offline-alert {
    margin-top: 5.9375rem;
  }

  .c-offline-alert p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .c-offline-alert {
    margin-top: 5.9375rem;
  }
}
