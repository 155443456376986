.c-course-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
}

.c-course-description {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
}

.c-course-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.c-course-box__title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.25rem;
  color: #ffffff;
}

.c-course-box a {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #6c81fa;
}

.c-course-infos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 150px;
  gap: 1rem;
}

.c-course-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ffffff1a;
  background-color: #28283d;
  padding-block: 1rem;
  padding-inline: 1.5rem;
}

.c-course-info p {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.125rem;
  color: #ffffff;
  text-align: center;
}

.c-course-skills ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: .75rem;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-course-infos {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-course-infos {
    grid-template-columns: repeat(3, 1fr);
  }
}
