.l-dashboard-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.l-dashboard-container h2 {
  align-self: flex-start;
}

.c-dashboard__input {
  align-self: flex-end;
  background-color: #232336;
  border: 1px solid #39394a;
  border-radius: 1.875rem;
  padding: 0.9rem 1.25rem;
  padding-left: 60px;
  background: url("../assets/images/search-normal.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 20px center;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
}

.c-dashboard__input:hover,
.c-dashboard__input:active {
  border: 1px solid #2d4bf8;
}

.c-dashboard__input::placeholder {
  color: #91919a;
}

.c-dashboard__table {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.c-dashboard__table::-webkit-scrollbar {
  height: 3px;
}

.c-dashboard__table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.c-dashboard__table::-webkit-scrollbar-thumb {
  background: #888;
}

.c-dashboard__table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.c-dashboard__table table {
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #1e1e30;
  border-radius: 1rem;
}

.c-dashboard__table table th,
.c-dashboard__table table td {
  font-size: 1.25rem;
  border: 1px solid #ffffff1a;
  padding: 1rem 2rem;
}

.c-dashboard__table table th {
  vertical-align: middle;
  font-family: "AtkinsonHyperlegible-Bold";
  color: #ffffff;
  background-color: #2d4bf8;
  cursor: pointer;
}

.c-dashboard__table table th img {
  vertical-align: middle;
  margin-left: 0.5rem;
}

.c-dashboard__table table td a {
  color: #ffffff;
}

.c-dashboard__table table th:first-of-type {
  border-top-left-radius: 1rem;
}

.c-dashboard__table table th:last-of-type {
  border-top-right-radius: 1rem;
}

.c-dashboard__table table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 1rem;
}

.c-dashboard__table table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 1rem;
}

.c-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  color: #161623;
}

.c-status--free {
  background-color: #35c544;
}

.c-status--occupied {
  background-color: #ff1a1a;
}

.c-dashboard__pagination {
  display: flex;
  align-self: flex-end;
  gap: 1rem;
}

.c-dashboard__pagination button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232336;
  border: 1px solid #39394a;
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

.c-dashboard__pagination button:disabled {
  opacity: 0.8;
}

.c-dashboard-pagination__input {
  height: 100%;
  background-color: #232336;
  border: 1px solid #39394a;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
  border-radius: 0.25rem;
  padding-left: 1rem;
}

/*****@Media*****/

@media (max-width: 768px) {
  .l-dashboard-container {
    width: 90vw;
    padding-inline: 0;
  }

  .c-dashboard__input {
    align-self: flex-start;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .l-dashboard-container {
    width: 80vw;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1580px) {
  .l-dashboard-container {
    width: 70vw;
  }
}
