.c-job-offer-card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 2fr;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid #ffffff1a;
  background-color: #1e1e30;
  text-decoration: none;
  padding: 2rem;
}

.c-job-offer-card:hover {
  background-color: #2d4bf8;
}

.c-job-offer-card div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.c-job-offer-card p {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
}

.c-job-offer-card__box:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  width: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 0.5rem;
}

.c-job-offer-card__box:nth-child(1) img {
  width: 100%;
  height: auto;
}

.c-job-offer-card__box:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: 1 / -1;
}

.c-job-offer-card__box:nth-child(3) {
  grid-column: 4 / 5;
  grid-row: 1 / -1;
}

.c-job-offer-card__box:nth-child(4) {
  grid-column: 5 / 6;
  grid-row: 1 / -1;
}

.c-job-offer-card__box:nth-child(3) p:nth-child(1),
.c-job-offer-card__box:nth-child(4) p:nth-child(1) {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.c-job-offer-card__box:nth-child(5) {
  grid-column: 6 / -1;
  grid-row: 1 / -1;
}

/*****@Media*****/

@media (max-width: 1400px) {
  .c-job-offer-card {
    grid-template-columns: 1fr 1fr;
    align-items: reset;
  }

  .c-job-offer-card__box:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .c-job-offer-card__box:nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .c-job-offer-card__box:nth-child(3) {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }

  .c-job-offer-card__box:nth-child(4) {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
  }

  .c-job-offer-card__box:nth-child(3) p:nth-child(2),
  .c-job-offer-card__box:nth-child(4) p:nth-child(2) {
    margin-left: 2rem;
  }

  .c-job-offer-card__box:nth-child(5) {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
    justify-self: flex-end;
    align-self: flex-start;
  }

  .c-job-offer-card__box:nth-child(5) img {
    width: 25px;
    height: auto;
  }
}
