.c-dropdown-container {
  position: relative;
}

.c-dropdown__btn {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background-color: #1e1e30;
  border: 1px solid #ffffff1a;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
}

.c-dropdown__btn span {
  display: none;
}

.c-dropdown__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: max-content;
  display: none;
  gap: 1rem;
  flex-direction: column;
  text-align: left;
  list-style: none;
  background-color: #1e1e30;
  border: 1px solid #ffffff1a;
  border-radius: 1rem;
  padding: 1.5rem;
  margin-top: 1rem;
}

.c-dropdown__list.expanded {
  display: flex;
}

.c-dropdown__header {
  font-family: "AtkinsonHyperlegible-Regular";
  color: #ffffff;
  font-size: 1.25rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.c-dropdown__item {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  border-radius: 3.5rem;
  border: 1px solid #ffffff1a;
  background-color: #232336;
  padding: 1rem 1.5rem;
  margin-left: 0.5rem;
}

.c-dropdown__checkbox {
  appearance: none;
  background-color: transparent;
  border: 2px solid #ffffff;
  margin: 0;
  padding: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff1a;
  cursor: pointer;
}

.c-dropdown__item--checked {
  background-color: #2d4bf8;
}

.c-dropdown__label {
  font-family: "AtkinsonHyperlegible-Regular";
  color: #ffffff;
  font-size: 1.125rem;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .c-dropdown__list {
    width: 80vw;
  }
}

@media only screen and (max-width: 1600px) {
  .c-dropdown__btn span {
    display: block;
    font-family: "AtkinsonHyperlegible-Regular";
    color: #ffffff;
    font-size: 1.5625rem;
  }
}
