.c-accordion__title--skills-list {
    font-family: "AtkinsonHyperlegible-Bold";
    font-size: 1.25rem;
    color: #ffffff;
    text-align: left;
}

.c-accordion__content--skills-list.open {
    padding: 2rem;
}

.c-skills-list {
    margin-top: 2rem;
}

.c-skills-list ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: .75rem
}