.c-loader {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 4rem;
  margin-inline: auto;
}

.c-loader img {
  width: 250px;
  height: 250px;
}

.c-loader p {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.875rem;
  color: #ffffff;
  text-align: center;
}

.c-no-data {
  width: 70vw;
  background-color: #f1eff9;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin: 2rem;
  padding: 3.75rem;
}

.c-no-data p {
  font-size: 1.5625rem;
  color: #10101b;
}

.c-no-data p img {
  height: 1.5625rem;
}

.c-no-data p:first-child {
  font-family: "AtkinsonHyperlegible-Bold";
}

.c-no-data p:last-child {
  font-family: "AtkinsonHyperlegible-Regular";
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-no-data {
    width: 90vw;
    gap: 0.5rem;
    margin: 0;
    margin-block: 2rem;
    padding: 1.75rem;
  }

  .c-no-data p {
    font-size: 1.5625rem;
  }
}