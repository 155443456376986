.l-select {
  display: flex;
  justify-content: center;
}

.l-select-container {
  position: relative;
  width: 100%;
}

.c-select-box__btn {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #232336;
  border: 1px solid #39394a;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
  border-radius: 1.875rem;
  padding: 0.9rem 1.25rem;
  text-align: left;
  cursor: pointer;
}

.c-select-box__btn:hover,
.c-select-box__btn:active,
.c-select-box__btn:focus {
  border: 1px solid #2d4bf8;
}

.c-select-box__btn span {
  display: inline-flex;
  gap: 1rem;
}

.c-select-box__listbox {
  width: inherit;
  height: 350px;
  position: absolute;
  display: none;
  background-color: #232336;
  border: 1px solid #39394a;
  border-radius: 0.275rem;
  list-style: none;
  padding: 1.5rem;
  margin-top: 1rem;
  z-index: 1;
  list-style: none;
  overflow-y: scroll;
}

.c-select-box__listbox::-webkit-scrollbar {
  width: 2px;
}

.c-select-box__listbox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.c-select-box__listbox::-webkit-scrollbar-thumb {
  background: #888;
}

.c-select-box__listbox::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.c-select-box__listbox.expanded {
  display: block;
}

.c-select-box__listbox li {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
  margin-block: 0.75rem;
}

.c-select-box__listbox li:first-of-type,
.c-select-box__listbox li:last-of-type {
  margin: 0 0;
}

.c-select-box__listbox li:active,
.c-select-box__listbox li:focus,
.c-select-box__listbox li:hover,
.c-select-box__listbox li[aria-selected="true"] {
  color: #2d4bf8;
  cursor: pointer;
}
