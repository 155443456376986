.c-no-courses-info {
    display: flex;
    gap: 2rem;
    background-color: #2d4bf8;
    border-radius: .5rem;
    padding: 2rem;
    margin-top: 1rem;
}

.c-no-courses-info__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-no-courses-info__box:first-child {
    flex-basis: 55%;
    gap: 2rem;
}

.c-no-courses-info__box:last-child {
    flex-basis: 45%;
}

.c-no-courses-info__box:last-child img {
    width: 100%;
    height: auto;
}

/*****@Media*****/

@media (max-width: 769px) {
    .c-no-courses-info {
        flex-direction: column;
    }

    .c-no-courses-info__box {
        flex-basis: 100%;
    }

    .c-no-courses-info__box:last-child {
        order: -1;
    }
}