.c-no-favorite-careers-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #2d4bf8;
    border-radius: .5rem;
    padding: 2rem;
}

.c-no-favorite-careers-info p:first-child {
    font-family: "AtkinsonHyperlegible-Bold";
    font-size: 2.2rem;
}

.c-no-favorite-careers-info p:first-child img {
    height: 2.2rem;
}

.c-no-favorite-careers-info p:last-child {
    font-family: "AtkinsonHyperlegible-Regular";
    font-size: 1.5625rem;
}

.c-no-favorite-careers-info span {
    width: fit-content;
    background-color: transparent;
    font-size: 1.25rem;
    font-family: "AtkinsonHyperlegible-Bold";
    color: #ffffff;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    border-radius: 1rem;
    display: inline-flex;
    align-items: baseline;
    gap: 1rem;
    margin: 0 0.5rem;
    padding: 1.5rem 2.5rem;
}

.c-no-favorite-careers-info img {
    align-self: center;
}

/*****@Media*****/

@media (max-width: 768px) {
    .c-no-favorite-careers-info p:first-child {
        font-size: 1.5rem;
    }

    .c-no-favorite-careers-info p:last-child {
        font-size: 1.25rem;
    }

    .c-no-favorite-careers-info span {
        display: flex;
        margin: 0.5rem 0;
        padding: 1rem 2rem;
    }
}