.c-job-report-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #2d4bf8;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-block: 2rem;
}

.c-job-report-info p:first-child {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2.2rem;
}

.c-job-report-info p:first-child img {
  height: 2.2rem;
}

.c-job-report-info p:last-child {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.5625rem;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-job-report-info p:first-child {
    font-size: 1.5rem;
  }

  .c-job-report-info p:last-child {
    font-size: 1.25rem;
  }
}
