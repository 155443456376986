.l-report-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
}

.l-report-container img {
  max-width: 100%;
  height: auto;
}

.apexcharts-canvas {
  margin: 0 auto;
}

/*****Welcome*****/

.c-report-welcome-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-block: 4rem;
}

.c-report-welcome-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

/*****Profile*****/

.c-report-profile {
  width: 100%;
  margin-block: 4rem;
}

.c-report-profile-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 1rem;
  margin-block: 4rem;
}

.c-report-profile-box:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  position: relative;
  background-color: #2d4bf8;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.c-report-profile-box:nth-child(1) div:nth-child(2) {
  flex-basis: 15%;
}

.c-report-profile-box:nth-child(1) div:nth-child(3) {
  flex-basis: 85%;
  background-image: url("../assets/images/NoBullshitJobs-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
}

.c-report-profile-box:nth-child(1) p span {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.5rem;
}

.c-report-profile-box:nth-child(2) {
  grid-column: 3 / -1;
  grid-row: 1 / 2;
  border-radius: 1rem;
  border: 1px solid #302d72;
}

.c-report-profile-box:nth-child(3) {
  grid-column: 1 / 2;
  grid-row: 2 / -1;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}

.c-report-profile-box:nth-child(4) {
  grid-column: 2 / 3;
  grid-row: 2 / -1;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}

.c-report-profile-box:nth-child(5) {
  grid-column: 3 / 4;
  grid-row: 2 / -1;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}

.c-report-profile-box:nth-child(6) {
  grid-column: 4 / 5;
  grid-row: 2 / -1;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}

.c-report-profile-box:nth-child(7) {
  grid-column: 5 / 6;
  grid-row: 2 / -1;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}

.c-report-profile-box:nth-child(8) {
  grid-column: 6 / -1;
  grid-row: 2 / -1;
  border-radius: 1rem;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}

.c-report-profile-box-i {
  background-color: #8c6ffe;
}

.c-report-profile-box-r {
  background-color: #6f8eff;
}

.c-report-profile-box-a {
  background-color: #ffd362;
}

.c-report-profile-box-s {
  background-color: #f67090;
}

.c-report-profile-box-e {
  background-color: #f8673a;
}

.c-report-profile-box-c {
  background-color: #73ab51;
}

.c-report-profile-box__title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.25rem;
  color: #1e1e30;
  z-index: 1;
}

.c-report-profile-box__value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-report-profile-box__value p {
  box-sizing: content-box;
  width: 60px;
  height: 24px;
  padding: 0.375rem 0.5rem;
  border-radius: 6.25rem;
  background-color: #1e1e30;
  z-index: 1;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-report-profile-box__value-i {
  color: #8c6ffe;
}

.c-report-profile-box__value-r {
  color: #6f8eff;
}

.c-report-profile-box__value-a {
  color: #ffd362;
}

.c-report-profile-box__value-s {
  color: #f67090;
}

.c-report-profile-box__value-e {
  color: #f8673a;
}

.c-report-profile-box__value-c {
  color: #73ab51;
}

/*****Profile description*****/

.c-report-profile-description-container {
  display: flex;
  justify-content: center;
  align-self: stretch;
  gap: 2rem;
  margin-block-start: 4rem;
  margin-block-end: 8rem;
}

.c-report-profile-description-box {
  position: relative;
  flex: 1;
  background-color: #1e1e30;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.c-report-profile-description-box:nth-child(1) {
  color: #10101b;
}

.c-report-profile-description-box:nth-child(
    1
  ).c-report-profile-description-box-i {
  background-color: #8c6ffe;
}

.c-report-profile-description-box:nth-child(
    1
  ).c-report-profile-description-box-r {
  background-color: #6f8eff;
}

.c-report-profile-description-box:nth-child(
    1
  ).c-report-profile-description-box-a {
  background-color: #ffd362;
}

.c-report-profile-description-box:nth-child(
    1
  ).c-report-profile-description-box-s {
  background-color: #f67090;
}

.c-report-profile-description-box:nth-child(
    1
  ).c-report-profile-description-box-e {
  background-color: #f8673a;
}

.c-report-profile-description-box:nth-child(
    1
  ).c-report-profile-description-box-c {
  background-color: #73ab51;
}

.c-report-profile-description-box:nth-child(2) {
  border: 1px solid #ffffff1a;
}

.c-report-profile-description-box:nth-child(3) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.c-report-profile-description-box:nth-child(
    3
  ).c-report-profile-description-box-i {
  background-color: #8c6ffe;
}

.c-report-profile-description-box:nth-child(
    3
  ).c-report-profile-description-box-r {
  background-color: #6f8eff;
}

.c-report-profile-description-box:nth-child(
    3
  ).c-report-profile-description-box-a {
  background-color: #ffd362;
}

.c-report-profile-description-box:nth-child(
    3
  ).c-report-profile-description-box-s {
  background-color: #f67090;
}

.c-report-profile-description-box:nth-child(
    3
  ).c-report-profile-description-box-e {
  background-color: #f8673a;
}

.c-report-profile-description-box:nth-child(
    3
  ).c-report-profile-description-box-c {
  background-color: #73ab51;
}

.c-report-profile-description-box span {
  font-size: 5.625rem;
  font-family: "AtkinsonHyperlegible-Bold";
}

/*****Characteristics*****/

.c-report-characteristics-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  margin-block-start: 4rem;
  margin-block-end: 8rem;
}

.c-report-characteristic-box {
  display: flex;
  background-color: #1e1e30;
  border-radius: 1rem;
  border: 1px solid #ffffff1a;
  text-align: center;
  padding: 2rem 1.5rem;
  gap: 1rem;
}

.c-report-characteristic-box__index {
  flex-basis: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-report-characteristic-box__index p {
  font-size: 2.5rem;
  font-family: "AtkinsonHyperlegible-Bold";
}

.c-report-characteristic-box__content {
  flex-basis: 90%;
  display: flex;
  align-items: center;
}

.c-report-characteristic-box__content p {
  text-align: left;
}

/******Developments*****/

.c-report-developments {
  margin-block: 4rem;
}

.c-report-developments-container {
  display: flex;
  gap: 2rem;
  margin-block: 4rem;
}

.c-report-development-box {
  flex: 1;
  background-color: #1e1e30;
  border-radius: 1rem;
  border: 1px solid #ffffff1a;
  padding: 2rem 1.5rem;
}

.c-report-development-box ul li {
  margin-inline: 1rem;
}

.c-report-development__title {
  font-family: "AtkinsonHyperlegible-Bold";
  margin-bottom: 1rem;
}

/*****CV advices*****/

.c-report-cv-advices-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-block: 4rem;
}

.c-report-cv-advice-box {
  flex: 1;
  background-color: #1e1e30;
  border-radius: 1rem;
  border: 1px solid #ffffff1a;
  text-align: center;
  padding: 2rem 1.5rem;
}

.c-report-cv-advice-box img {
  margin-bottom: 1rem;
}

.c-report-cv-advice-box p {
  text-align: left;
}

/*****CV advices example*****/

.c-report-cv-advices-example-container {
  display: flex;
  gap: 2rem;
  margin-block-start: 2rem;
  margin-block-end: 4rem;
}

.c-report-cv-advices-example-box p:nth-of-type(1) {
  font-family: "AtkinsonHyperlegible-Bold";
}

.c-report-cv-advices-example-box p:nth-of-type(2) {
  font-family: "AtkinsonHyperlegible-Regular";
  color: #6d96ff;
}

/*****Careers*****/

.c-report-careers {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 4rem;
}

.c-report-careers button {
  margin-top: 2rem;
}

/*****Discord*****/

.l-report-discord {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #2d4bf8;
  padding-block: 4rem;
  margin-block: 4rem;
}

.c-report-discord {
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.c-report-discord button {
  margin-top: 2rem;
}

/*****@Media*****/

@media (max-width: 768px) {
  .l-report-container {
    width: 90vw;
    padding-inline: 0;
  }

  /*****Welcome*****/

  .c-report-welcome-container {
    flex-direction: column;
    margin-block: 0;
  }

  .c-report-welcome-box:nth-child(2) {
    order: -1;
    display: flex;
    align-items: center;
  }

  .c-report-welcome-box h2 {
    font-size: 2.5rem;
  }

  /*****Profile*****/

  .c-report-profile-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    margin-block: 1rem;
  }

  .c-report-profile-box:nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .c-report-profile-box:nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .c-report-profile-box:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    padding: 1rem;
  }

  .c-report-profile-box:nth-child(4) {
    grid-column: 2 / -1;
    grid-row: 3 / 4;
    padding: 1rem;
  }

  .c-report-profile-box:nth-child(5) {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    padding: 1rem;
  }

  .c-report-profile-box:nth-child(6) {
    grid-column: 2 / -1;
    grid-row: 4 / 5;
    padding: 1rem;
  }

  .c-report-profile-box:nth-child(7) {
    grid-column: 1 / 2;
    grid-row: 5 / -1;
    padding: 1rem;
  }

  .c-report-profile-box:nth-child(8) {
    grid-column: 2 / -1;
    grid-row: 5 / -1;
    padding: 1rem;
  }

  .c-report-profile-box__title {
    font-size: 1rem;
  }

  /*****Profile description*****/

  .c-report-profile-description {
    margin-block: 2rem;
  }

  .c-report-profile-description-container {
    flex-direction: column;
    margin-block: 1rem;
    gap: 1rem;
  }

  /*****Characteristics*****/

  .c-report-characteristics {
    margin-block: 2rem;
  }

  .c-report-characteristics-container {
    grid-template-columns: 1fr;
    margin-block: 1rem;
    gap: 1rem;
  }

  /******Developments*****/

  .c-report-developments {
    margin-block: 4rem;
  }

  .c-report-developments-container {
    flex-direction: column;
    margin-block: 2rem;
    gap: 1rem;
  }

  /*****CV advices*****/

  .c-report-cv {
    margin-block: 2rem;
  }

  .c-report-cv-advices-container {
    flex-direction: column;
    margin-block-start: 2rem;
    margin-block-end: 4rem;
    gap: 1rem;
  }

  /*****CV advices example*****/

  .c-report-cv-advices-example-container {
    flex-direction: column;
    margin-block: 2rem;
  }

  /*****Careers*****/

  .c-report-careers {
    margin-block: 0;
  }

  /*****Discord*****/

  .c-report-discord {
    width: 90vw;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1040px) {
  .l-report-container {
    width: 80vw;
  }

  /*****Welcome*****/

  .c-report-welcome-container {
    flex-direction: column;
  }

  .c-report-welcome-container img {
    max-width: 80%;
  }

  .c-report-welcome-box:nth-child(2) {
    order: -1;
    display: flex;
    align-items: center;
  }

  /*****Profile*****/

  .c-report-profile-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
  }

  .c-report-profile-box:nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .c-report-profile-box:nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .c-report-profile-box:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .c-report-profile-box:nth-child(4) {
    grid-column: 2 / -1;
    grid-row: 3 / 4;
  }

  .c-report-profile-box:nth-child(5) {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  .c-report-profile-box:nth-child(6) {
    grid-column: 2 / -1;
    grid-row: 4 / 5;
  }

  .c-report-profile-box:nth-child(7) {
    grid-column: 1 / 2;
    grid-row: 5 / -1;
  }

  .c-report-profile-box:nth-child(8) {
    grid-column: 2 / -1;
    grid-row: 5 / -1;
  }

  /*****Profile description*****/

  .c-report-profile-description-container {
    flex-direction: column;
  }

  /*****Characteristics*****/

  /*****Developments*****/

  /*****CV advices*****/

  /*****CV advices example*****/

  /*****Careers*****/

  /*****Discord*****/
}

@media only screen and (min-width: 1041px) and (max-width: 1200px) {
  .l-report-container {
    width: 80vw;
  }

  /*****Welcome*****/

  .c-report-welcome-container {
    flex-direction: column;
  }

  .c-report-welcome-container img {
    max-width: 80%;
  }

  .c-report-welcome-box:nth-child(2) {
    order: -1;
    display: flex;
    align-items: center;
  }

  /*****Profile*****/

  .c-report-profile-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
  }

  .c-report-profile-box:nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .c-report-profile-box:nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .c-report-profile-box:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .c-report-profile-box:nth-child(4) {
    grid-column: 2 / -1;
    grid-row: 3 / 4;
  }

  .c-report-profile-box:nth-child(5) {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  .c-report-profile-box:nth-child(6) {
    grid-column: 2 / -1;
    grid-row: 4 / 5;
  }

  .c-report-profile-box:nth-child(7) {
    grid-column: 1 / 2;
    grid-row: 5 / -1;
  }

  .c-report-profile-box:nth-child(8) {
    grid-column: 2 / -1;
    grid-row: 5 / -1;
  }

  /*****Profile description*****/

  /*****Characteristics*****/

  /*****Developments*****/

  /*****CV advices*****/

  /*****CV advices example*****/

  /*****Careers*****/

  /*****Discord*****/
}

@media only screen and (min-width: 1201px) and (max-width: 1580px) {
  .l-report-container {
    width: 70vw;
  }

  /*****Welcome*****/

  .c-report-welcome-container {
    flex-direction: column;
  }

  .c-report-welcome-container img {
    max-width: 80%;
  }

  .c-report-welcome-box:nth-child(2) {
    order: -1;
    display: flex;
    align-items: center;
  }

  /*****Profile*****/

  .c-report-profile-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
  }

  .c-report-profile-box:nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .c-report-profile-box:nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .c-report-profile-box:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .c-report-profile-box:nth-child(4) {
    grid-column: 2 / -1;
    grid-row: 3 / 4;
  }

  .c-report-profile-box:nth-child(5) {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  .c-report-profile-box:nth-child(6) {
    grid-column: 2 / -1;
    grid-row: 4 / 5;
  }

  .c-report-profile-box:nth-child(7) {
    grid-column: 1 / 2;
    grid-row: 5 / -1;
  }

  .c-report-profile-box:nth-child(8) {
    grid-column: 2 / -1;
    grid-row: 5 / -1;
  }

  /*****Profile description*****/

  /*****Characteristics*****/

  /*****Developments*****/

  /*****CV advices*****/

  /*****CV advices example*****/

  /*****Careers*****/

  /*****Discord*****/
}
