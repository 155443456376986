.l-skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-skills {
  width: 70vw;
}

.c-skills__box:first-child {
  margin-bottom: 10rem;
}

.c-skills__box:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.c-skills__box:last-child {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 5rem;
}

.c-skills__content {
  display: flex;
  gap: 1.5rem;
}

.c-skills__content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.c-skills__content-box:first-child {
  gap: 4rem;
}

.c-skills__content-box:last-child {
  align-items: flex-end;
}

.c-skills__info {
  position: relative;
  background-color: #2D4BF8;
  padding: 1.5rem;
  padding-right: 10rem;
  border-radius: 1rem;
  overflow: hidden;
}

.c-skills__info-title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 4.375rem;
  color: #ffffff;
}

.c-skills__info-title img {
  height: 2.25rem;
}

.c-skills__info-subtitle {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.438rem;
  color: #ffffff;
}

.c-skills__list {
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-skills {
    width: 90vw;
  }

  .c-skills__box:first-child {
    margin-bottom: 5rem;
  }

  .c-skills__box:last-child {
    margin-block: 2rem;
  }

  .c-skills__info {
    width: 100%;
    padding-right: 4rem;
  }

  .c-skills__list {
    width: 90vw;
  }

  .c-skills__content {
    flex-direction: column;
  }

  .c-skills__content-box:last-child {
    align-items: stretch;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-skills {
    width: 80vw;
  }

  .c-skills__list {
    width: 80vw;
  }

  .c-skills__content {
    flex-direction: column;
  }

  .c-skills__content-box:last-child {
    align-items: stretch;
  }
}
