.l-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.c-not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.c-not-found-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.c-not-found-box img {
  max-width: 90%;
  height: auto;
}

.c-not-found-box h2 {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2.5rem;
}

.c-not-found-box p {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .c-not-found-container {
    width: 90vw;
    flex-direction: column;
    text-align: center;
  }

  .c-not-found-box {
    gap: 1rem;
  }

  .c-not-found-box h2 {
    font-family: "AtkinsonHyperlegible-Bold";
    font-size: 2rem;
  }

  .c-not-found-box p {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .c-not-found-container {
    width: 80vw;
    flex-direction: column;
    text-align: center;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1610px) {
  .c-not-found-container {
    width: 70vw;
  }
}