* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #161623;
  font-family: "AtkinsonHyperlegible-Regular";
  color: #ffffff;
}

@font-face {
  font-family: "AtkinsonHyperlegible-Bold";
  src: local("AtkinsonHyperlegible-Bold"),
    url("./assets/fonts/AtkinsonHyperlegible-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "AtkinsonHyperlegible-BoldItalic";
  src: local("AtkinsonHyperlegible-BoldItalic"),
    url("./assets/fonts/AtkinsonHyperlegible-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "AtkinsonHyperlegible-Italic";
  src: local("AtkinsonHyperlegible-Italic"),
    url("./assets/fonts/AtkinsonHyperlegible-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "AtkinsonHyperlegible-Regular";
  src: local("AtkinsonHyperlegible-Regular"),
    url("./assets/fonts/AtkinsonHyperlegible-Regular.ttf") format("truetype");
}
