.c-skill {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  padding: 1rem 1.5rem;
  border-radius: 3.5rem;
  border: 1px solid #ffffff1a;
  background-color: #232336;
}

.c-skill--checked {
  background-color: #2d4bf8;
}

.c-skill__checkbox {
  appearance: none;
  background-color: transparent;
  border: 2px solid #ffffff;
  margin: 0;
  padding: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff1a;
  cursor: pointer;
}

.c-skill__label {
  font-family: "AtkinsonHyperlegible-Regular";
  color: #ffffff;
  font-size: 1.25rem;
  cursor: pointer;
}

/*****@Media*****/

@media (max-width: 769px) {
  .c-skill {
    gap: 1rem;
  }
}
