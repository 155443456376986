.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  outline: 0;
}

p,
li {
  line-height: 1.5;
  font-size: 1.25rem;
}

img.c-icon {
  height: 1.5rem;
}

strong {
  font-family: "AtkinsonHyperlegible-Bold";
}

.mt-4 {
  margin-top: 1rem;
}

.heading {
  font-family: "AtkinsonHyperlegible-Bold";
  color: #ffffff;
}

.heading--small-size {
  font-size: 1.25rem;
  font-family: "AtkinsonHyperlegible-Regular";
}

.heading--dark-blue-color {
  color: #10101b;
}

.heading--blue-color {
  color: #6e8eff;
}

.heading--weight-400 {
  font-family: "AtkinsonHyperlegible-Regular";
  font-weight: 400;
}

.heading--weight-700 {
  font-family: "AtkinsonHyperlegible-Regular";
  font-weight: 700;
}

.heading--weight-regular {
  font-family: "AtkinsonHyperlegible-Regular";
}

.heading--weight-bold {
  font-family: "AtkinsonHyperlegible-Bold";
}

.heading--color-underline {
  position: relative;
}

.heading--color-underline::after {
  content: "";
  position: absolute;
  width: 105%;
  height: 15px;
  bottom: 0px;
  left: 0;
  z-index: -1;
}

.heading--blue-color-underline::after {
  background-color: #2d4bf8;
}

.heading--purple-color-underline::after {
  background-color: #8c6ffe;
}

.heading-h2 {
  font-size: 3.75rem;
}

.heading-h3 {
  font-size: 2.5rem;
}

.heading-h4 {
  font-size: 1.25rem;
}

.heading-h2 img {
  height: 3.75rem;
}

.heading-h3 img {
  height: 2.5rem;
  margin-left: 0.25rem;
}

.heading-h4 img {
  height: 1.25rem;
  margin-left: 0.25rem;
}

h2:focus,
h3:focus,
h4:focus {
  outline: none;
}

main {
  padding-top: 15vh;
  min-height: 100vh;
}

.c-content-logo {
  display: block;
  margin-left: auto;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.text--center {
  text-align: center;
}

.text--bold {
  font-family: "AtkinsonHyperlegible-Bold";
}

.text--padding-t-1 {
  padding-top: 0.5rem;
}

img.c-decoration-img {
  position: absolute;
  top: 0;
  right: 0;
}

img.c-decoration-img--bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.c-btn {
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 1rem;
  margin-block: 0.5rem;
}

.c-btn--in-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.c-btn--in-icon img.c-icon {
  height: 1.5rem;
}

.c-btn--small-size {
  border-radius: 6.25rem;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.25rem;
}

.c-btn--small-size img {
  height: 1.25rem;
}

.c-btn--regular-size {
  border-radius: 3.625rem;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2rem;
}

.c-btn--regular-size img {
  height: 2rem;
}

.c-btn--blue-color {
  background-color: #2d4bf8;
}

.c-btn--dark-blue-color {
  background-color: #10101b;
}

.c-btn--red-color {
  background-color: #cd1414;
}

.c-btn--100-w {
  width: 100%;
}

.c-btn:hover,
.c-btn:disabled {
  opacity: 0.9;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
}

.l-home-container {
  display: flex;
  justify-content: center;
  margin-inline: auto;
}

.c-home {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.c-home--regular-width {
  width: 40vw;
}

.c-home--middle-width {
  width: 60vw;
}

.c-home__title p {
  color: #ffffff;
  font-family: "AtkinsonHyperlegible-Regular";
}

.c-home__content {
  display: flex;
  background-color: #f1eff9;
  border-radius: 1rem;
  padding: 2rem;
}

.c-home__content--column {
  flex-direction: column;
}

.c-home__box {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-home__box img {
  max-width: 100%;
  height: auto;
}

/*****Form******/

.c-form {
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.c-form--large-width {
  width: 80%;
}

.c-form--full-width {
  width: 100%;
}

.c-form__title {
  color: #161623;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.875rem;
}

.c-form p,
.c-form ul li {
  color: #161623;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1rem;
}

.c-form ul {
  margin-block: 0.5rem;
  margin-inline-start: 2rem;
}

.c-form label {
  color: #161623;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.25rem;
}

.c-form__link {
  color: #161623;
  font-family: "AtkinsonHyperlegible-Bold";
  text-decoration: underline;
}

.c-form__link--blue {
  color: #2d4bf8;
}

.c-form__group {
  display: flex;
  align-items: center;
  margin-block: 0.25rem;
  position: relative;
}

.c-btn-toggle-password {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
}

.c-form__group .c-form__label {
  color: #161623;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.125rem;
  margin-left: 1rem;
  width: 70%;
}

.c-form__input {
  display: block;
  background-color: #ffffff;
  padding: 0.9rem 1.25rem;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.125rem;
  border: 1px solid #878a9c;
  border-radius: 6.25rem;
}

.c-form__input--password {
  width: 100%;
}

.c-form__checkbox {
  appearance: none;
  background-color: #ffffff;
  border: 2px solid #2d4bf8;
  margin: 0;
  width: 2.5em;
  height: 2.5em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"]:checked::before {
  content: url("../assets/images/check.svg");
}

input[type="checkbox"]:checked {
  background-color: #2d4bf8;
}

.c-oath-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/*****Form errors******/

.c-form__checkbox--error,
.c-form__input--error {
  border: 2px solid #cd1414;
}

p.c-form__error-message {
  color: #cd1414;
}

#isRulesConfirmedDesc {
  display: none;
}

/*****Accordion*****/

.c-accordion {
  width: 100%;
  margin-block: 1rem;
}

.c-accordion__btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 1rem;
  background-color: transparent;
  border: 1px solid #ffffff1a;
  background-color: #232336;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
}

.c-accordion__btn.active {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

.c-accordion__btn p {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.25rem;
  color: #ffffff;
  text-align: left;
}

.c-accordion__content {
  display: none;
}

.c-accordion__content.open {
  display: block;
  border: 1px solid #ffffff1a;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: #232336;
}

/*****@Media*****/

@media (max-width: 768px) {
  .heading-h2 {
    font-size: 2.5rem;
  }

  .heading-h3 {
    font-size: 1.375rem;
  }

  .heading-h4 {
    font-size: 1rem;
  }

  .heading-h2 img {
    height: 2.5rem;
  }

  .heading-h3 img {
    height: 1.375rem;
  }

  .heading-h4 img {
    height: 1rem;
  }

  img.c-icon,
  img.c-icon {
    height: 1.25rem;
  }

  .c-btn--in-icon {
    padding-inline: 2.5rem;
    padding-block: 1.5rem;
  }

  .c-btn--regular-size {
    font-size: 1.5625rem;
  }

  .c-btn--regular-size img {
    height: 1.5625rem;
  }

  .l-home-container {
    width: 85vw;
  }

  .c-home__content {
    padding: 1rem;
    flex-direction: column;
  }

  .c-home--regular-width {
    width: 80vw;
  }

  .c-home--middle-width {
    width: 90vw;
  }

  /*****Form******/

  .c-form--large-width {
    width: 85%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .l-home-container {
    width: 80vw;
  }

  .c-home__content {
    padding: 1rem;
    flex-direction: column;
  }

  .c-home--regular-width {
    width: 60vw;
  }

  .c-home--middle-width {
    width: 70vw;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1610px) {
  .l-home-container {
    width: 80vw;
  }

  .c-home--regular-width {
    width: 60vw;
  }

  .c-home--middle-width {
    width: 90vw;
  }
}
