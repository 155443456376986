.l-careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-careers {
  width: 70vw;
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  gap: 1em;
}

.c-careers__box:first-child {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.c-careers__box:nth-child(2) {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
}

.c-careers__box:nth-child(3) {
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  justify-self: center;
}

.c-careers__box:nth-child(4) {
  grid-column: 1 / -1;
  grid-row: 4 / 5;
  justify-self: start;
}

.c-careers__box:last-child {
  grid-column: 1 / -1;
  grid-row: 5 / -1;
  justify-self: center;
}

.c-careers__content {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.c-careers__content-box:first-child,
.c-careers__content-box:nth-child(2) {
  position: relative;
  background-color: #1e1e30;
  padding: 2rem;
  border: 1px solid #ffffff1a;
  overflow: hidden;
}

.c-careers__content-box:first-child {
  flex: 1;
  border-radius: 1rem;
}

.c-careers__content-box:nth-child(2) {
  flex: 1;
  background-color: #2d4bf8;
  border-radius: 1rem;
}

.c-careers__content-box:last-child {
  flex: 1;
  display: flex;
  justify-content: center;
}

.c-careers__content-title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 4.375rem;
  color: #ffffff;
}

.c-careers__content-subtitle {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.438rem;
  color: #ffffff;
}

.c-careers__content-subtitle--light {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
}

.c-careers-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.c-careers-filter__box {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.c-careers-filter__title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.5625rem;
  color: #f1eff9;
  text-align: left;
  text-transform: uppercase;
}

.c-careers__list {
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-btn-show-more {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.5625rem;
  color: #f1eff9;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  margin: 4rem 1rem;
}

.c-btn-show-more--disabled {
  color: #87899c;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-careers {
    width: 90vw;
  }

  .c-careers-filter__box {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-careers__list {
    width: 90vw;
  }

  .c-careers__content {
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
  }

  .c-careers__content-box:last-child {
    order: -1;
  }

  .c-careers__content-box:last-child img {
    width: 90%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-careers {
    width: 80vw;
  }

  .c-careers-filter__box {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-careers__list {
    width: 80vw;
  }

  .c-careers__content {
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
  }

  .c-careers__content-box:last-child {
    order: -1;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .c-careers {
    width: 70vw;
  }

  .c-careers-filter__box {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-careers__list {
    width: 70vw;
  }

  .c-careers__content {
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
  }

  .c-careers__content-box:last-child {
    order: -1;
  }
}