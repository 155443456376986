.c-add-home-notification {
    position: fixed;
    width: 25vw;
    top: 1rem;
    right: 1rem;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 1rem;
    border: 1px solid #ffffff1a;
    background-color: #1e1e30;
    gap: .5rem;
    padding: 1rem;
    overflow: hidden;
}

.c-add-home-notification p {
    align-self: flex-start;
    font-family: "AtkinsonHyperlegible-Regular";
    font-size: 1.125rem;
    color: #ffffff;
}

.c-add-home-notification button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 4;
}

button.c-btn-add-home-notification {
    border-radius: 1rem;
    font-family: "AtkinsonHyperlegible-Bold";
    font-size: 1.125rem;
    color: #ffffff;
    text-align: center;
    background-color: #2d4bf8;
    margin-block: .5rem;
    padding: 1rem;
    z-index: 4;
}

/*****@Media*****/

@media (max-width: 768px) {
    .c-add-home-notification {
        position: fixed;
        width: 95vw;
        top: auto;
        left: 0;
        right: 0;
        bottom: .5rem;
        margin: 0 auto;
    }
}
