.c-alert {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #161623;
}

.c-alert--error {
  background-color: #ff1a1a;
}

.c-alert--success {
  background-color: #35c544;
}

.c-alert__title {
  align-self: flex-start;
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 1.875rem;
  color: #161623;
}