footer {
  position: relative;
}

.c-footer {
  position: absolute;
  top: 0;
}

/*****Info*****/

.c-footer__info {
  display: grid;
  grid-template-columns: min-content repeat(5, auto);
  grid-template-rows: repeat(2, auto);
  gap: 1.5rem;
  padding: 4rem;
}

.c-footer-info__box:nth-child(1) {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: flex-end;
  align-self: center;
}

.c-footer-info__box:nth-child(2) {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: flex-start;
  align-self: center;
}

.c-footer-info__box:nth-child(3) {
  grid-column: 5/-1;
  grid-row: 1/2;
  justify-self: flex-end;
  align-self: center;
}

.c-footer-info__box:nth-child(3) ul {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.c-footer-info__box:nth-child(3) li a {
  color: #ffffff;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  text-decoration: none;
}

.c-footer-info__box:nth-child(4) {
  grid-column: 2/3;
  grid-row: 2/-1;
}

/*****Contact*****/

.c-footer__contact {
  display: flex;
}

.c-footer-contact__box {
  flex: 1;
}

.c-footer__contact a {
  color: #ffffff;
}

/*****Logos*****/

.c-footer__logos {
  display: flex;
  background-color: #ffffff;
  padding-block: 1rem;
}

.c-footer__logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-footer__logo img {
  width: 40%;
  height: auto;
}

/*****Description*****/

.c-footer__description {
  display: flex;
  background-color: #ffffff;
  padding-block: 2rem;
}

.c-footer-description__box {
  flex: 1;
  padding-inline: 4rem;
}

.c-footer-description__box ul {
  margin-inline-start: 1rem;
}

.c-footer-description__box p, .c-footer-description__box ul li {
  font-family: "AtkinsonHyperlegible-Regular";
  color: #5B5B5B;
  font-size: 0.875rem;
}

/*****@Media*****/

@media (max-width: 1200px) {
  .c-footer {
    padding-top: 0;
  }

  .c-footer img {
    transform: scale(0.8);
  }

  /*****Info*****/

  .c-footer__info {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: repeat(4, auto);
    gap: 1.5rem;
    padding-block: 4rem;
    padding-inline: 2rem;
  }

  .c-footer-info__box:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .c-footer-info__box:nth-child(2) {
    grid-column: 2/-1;
    grid-row: 1/2;
  }

  .c-footer-info__box:nth-child(3) {
    grid-column: 1/-1;
    grid-row: 2/3;
    justify-self: flex-start;
  }

  .c-footer-info__box:nth-child(3) ul {
    flex-direction: column;
    gap: .5rem;
  }

  .c-footer-info__box:nth-child(4) {
    grid-column: 1/-1;
    grid-row: 3/-1;
  }

  /*****Contact*****/

  .c-footer__contact {
    flex-direction: column;
    gap: 2rem;
  }

  /*****Logos*****/

  .c-footer__logos {
    grid-template-columns: 1fr;
  }

  .c-footer__logos {
    flex-direction: column;
  }

  .c-footer__logo img {
    width: 70%;
  }

  /*****Description*****/

  .c-footer__description {
    flex-direction: column;
    gap: 2rem;
  }

  .c-footer-description__box {
    padding-inline: 2rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {

  /*****Info*****/

  .c-footer-info__box:nth-child(3) {
    justify-self: flex-start;
  }

  /*****Logo*****/

  .c-footer__logo img {
    width: 35%;
  }
}