.l-job-offers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-job-offers {
  width: 70vw;
}

.c-job-offers .c-job-offers__box:nth-child(2) p {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.c-job-offers-filter {
  display: flex;
  gap: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #ffffff1a;
  background-color: #1e1e30;
  padding: 2rem;
  margin-block: 2rem;
}

.c-job-offers-filter .c-job-offers__box:nth-child(1) {
  flex-basis: 50%;
  display: flex;
  align-items: center;
}

.c-job-offers-filter .c-job-offers__box:nth-child(2) {
  flex-basis: 25%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.c-job-offers-filter .c-job-offers__box:nth-child(3) {
  flex-basis: 25%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.c-job-offers__input {
  width: 100%;
  background-color: #232336;
  border: 1px solid #39394a;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
  border-radius: 1.875rem;
  padding: 0.9rem 1.25rem;
  padding-left: 60px;
  background: url("../assets/images/search-normal.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 20px center;
}

.c-job-offers__input:hover,
.c-job-offers__input:active {
  border: 1px solid #2d4bf8;
}

.c-job-offers__input::placeholder {
  color: #91919a;
}

.c-job-offers__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

/*****Doing good*****/

.l-doing-good {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f05b37;
  padding-block: 4rem;
  margin-block: 4rem;
}

.c-doing-good {
  width: 70vw;
  display: flex;
  align-items: center;
  gap: 5rem;
}

.c-doing-good h3 {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 3.75rem;
  color: #ffffff;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-job-offers {
    width: 90vw;
  }

  .c-job-offers-filter {
    flex-direction: column;
    margin-block: 2rem;
  }

  .c-doing-good {
    width: 90vw;
    flex-direction: column;
  }

  .c-doing-good h3 {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-job-offers {
    width: 80vw;
  }

  .c-doing-good {
    width: 80vw;
  }

  .c-job-offers-filter {
    flex-direction: column;
  }
}
