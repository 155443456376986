.c-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #161623;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  z-index: 3;
}

.c-navbar__logo {
  display: inline-block;
  padding-block: 0.3125rem;
  margin-right: 1rem;
}

.c-navbar-menu {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.c-navbar-menu li a {
  color: #ffffff;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  text-decoration: none;
}

.c-navbar-menu li a.active {
  font-family: "AtkinsonHyperlegible-Bold";
  text-decoration: underline;
}

.c-navbar__btn {
  display: flex;
  gap: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
}

.c-navbar__btn--discord {
  color: #161623;
  background-color: #8d78e8;
  border-radius: 6rem;
  padding: 0.5rem 1rem;
}

/******Dropdown menu******/

.c-navbar-dropdown-menu {
  position: relative;
}

.c-navbar-dropdown-menu .c-navbar-dropdown-menu__list {
  display: none;
  position: absolute;
  top: 4rem;
  left: -2rem;
}

.c-navbar-dropdown-menu .c-navbar-dropdown-menu__list.open {
  display: block;
  width: max-content;
  list-style: none;
  background-color: #161623;
}

.c-navbar-dropdown-menu .c-navbar-dropdown-menu__list.open li a {
  display: flex;
  gap: 1rem;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #ffffff;
}

.c-navbar-dropdown-menu .c-navbar-dropdown-menu__list.open li .c-navbar__btn {
  width: 100%;
  padding: 1.5rem 2rem;
}

/******Mobile menu******/

.c-navbar-hamburger-menu__btn {
  display: none;
  width: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.c-navbar-hamburger-menu__btn span {
  display: block;
  background-color: #ffffff;
  border-radius: 4px;
  height: 4px;
  margin: 8px 0;
}

.c-navbar-menu__item--mobile {
  display: none;
}

@media (max-width: 1200px) {
  .c-navbar {
    padding: 0;
    padding-bottom: 1rem;
  }

  .c-navbar h1 {
    margin-left: 2rem;
    margin-top: 2rem;
  }

  .c-navbar-hamburger-menu__btn {
    margin-right: 2rem;
    margin-top: 2rem;
  }

  .c-navbar-menu {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-top: 1rem;
    gap: 0;
  }

  .c-navbar-menu {
    display: none;
  }

  .c-navbar-menu.open {
    position: absolute;
    top: 100%;
    display: flex;
    background-color: #161623;
  }

  .c-navbar-menu.open li {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ffffff;
  }

  .c-navbar-menu.open li:first-child {
    border-top: 1px solid #ffffff;
  }

  .c-navbar-menu.open li:last-child {
    border-bottom: 1px solid #ffffff;
  }

  .c-navbar-menu.open li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    padding: 1rem 2rem;
  }

  .c-navbar-menu.open li a.active {
    font-family: "AtkinsonHyperlegible-Bold";
    text-decoration: underline;
  }

  .c-navbar-menu.open .c-navbar-dropdown-menu {
    display: none;
  }

  .c-navbar-hamburger-menu__btn {
    display: block;
  }

  .c-navbar-menu.open .c-navbar-menu__item--mobile {
    display: block;
  }

  .c-navbar-menu.open .c-navbar-menu__item--mobile a {
    display: flex;
    gap: 1rem;
  }

  .c-navbar__btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 6.125rem;
    padding: 0;
    margin: 1rem 2rem;
    color: #ffffff;
  }
}
