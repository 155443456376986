.l-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-settings {
  width: 70vw;
}

.c-settings__box .c-btn {
  width: fit-content;
}

.c-settings__title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2.5rem;
  color: #ffffff;
}

.c-settings__box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f1eff9;
  border-radius: 1.25rem;
  padding: 2rem;
  margin-block: 2rem;
}

.c-settings__box-content {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #161623;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-settings {
    width: 90vw;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-settings {
    width: 80vw;
  }
}
