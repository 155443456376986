.c-oath__btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  margin-block: 0.5rem;
  padding: 1rem;
}

.c-oath__btn--google {
  border: 2px solid #ea4335;
}

.c-oath__btn--facebook {
  border: 2px solid #2d4bf8;
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-oath__btn {
    width: 50px;
    height: 50px;
    padding: 0.5rem;
  }
}
