.l-job-offer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-job-offer__header,
.c-job-offer__content {
  width: 70vw;
}

.c-job-offer__header {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  gap: 2rem;
  margin-bottom: 4rem;
}

.c-job-offer__header .c-job-offer__box:nth-child(1) {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.c-job-offer__header .c-job-offer__box:nth-child(1) a {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 1.25rem;
  color: #ffffff;
  text-decoration: none;
}

.c-job-offer__header .c-job-offer__box:nth-child(2) {
  grid-column: 1 / 2;
  grid-row: 2 / -1;
}

.c-job-offer__header .c-job-offer__box:nth-child(3) {
  grid-column: 2 / -1;
  grid-row: 2 / -1;
}
.c-job-offer__company-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  min-height: 150px;
  border-radius: 0.5rem;
  border: 1px solid #ffffff1a;
  background-color: #ffffff;
  padding: 0.5rem;
}

.c-job-offer__company-logo img {
  width: 100%;
  height: auto;
}

.c-job-offer__name {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 3.75rem;
  color: #ffffff;
}

.c-job-offer__company {
  font-family: "AtkinsonHyperlegible-Regular";
  font-size: 2.5rem;
  color: #ffffff;
}

.c-job-offer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid #ffffff1a;
  background-color: #1e1e30;
  padding: 2rem;
}

.c-job-offer__content ul {
  padding: revert;
}

.c-job-offer__content .c-job-offer__box:nth-child(2) {
  width: 100%;
  margin-block: 0;
  text-align: left;
}

.c-job-offer__box {
  width: 100%;
  margin-block: 1rem;
  text-align: left;
}

.c-job-offer__box a {
  font-family: "AtkinsonHyperlegible-Bold";
  color: #ffffff;
  text-decoration: underline;
}

.c-job-offer__box__title {
  font-family: "AtkinsonHyperlegible-Bold";
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1.25rem;
}

.c-job-offer-infos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.c-job-offer-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #ffffff1a;
  background-color: #28283d;
  padding: 2rem;
}

.c-job-offer-info p:nth-child(1) {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.c-job-offer-info p:nth-child(2) {
  font-family: "AtkinsonHyperlegible-Bold";
}

/*****@Media*****/

@media (max-width: 768px) {
  .c-job-offers,
  .c-job-offer__header,
  .c-job-offer__content {
    width: 90vw;
  }
  .c-job-offer__header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .c-job-offer__header .c-job-offer__box:nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .c-job-offer__header .c-job-offer__box:nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .c-job-offer__header .c-job-offer__box:nth-child(3) {
    grid-column: 1 / -1;
    grid-row: 3 / -1;
  }

  .c-job-offer__name {
    font-size: 2.5rem;
  }

  .c-job-offer__company,
  .c-job-offer__box__title {
    font-size: 1.375rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .c-job-offers,
  .c-job-offer__header,
  .c-job-offer__content {
    width: 80vw;
  }
}
